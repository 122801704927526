import { SelectData } from '../../../common/filter/models'
import TicketConfirmedFilter from '../../../common/filter/TicketConfirmedFilter'

const ActiveTicketsFilter = ({
  initialFilter,
  updateFilters,
  callback,
}: {
  initialFilter: any
  updateFilters: (key: any) => void
  callback: (param1?: boolean, param2?: boolean) => void
}) => {
  const onChangeSelect = (selectData: SelectData) => {
    updateFilters((prevState: any) => ({
      ...prevState,
      euConfirmed: selectData.value === 'Confirmed',
    }))
  }

  return (
    <div className='row'>
      <div className='col-lg-4'>
        <div className='row align-items-md-center'>
          <TicketConfirmedFilter
            changeSelect={onChangeSelect}
            value={initialFilter.euConfirmed ? 'Confirmed' : 'Pending'}
          />
        </div>
      </div>
      <div className='d-flex flex-column flex-sm-row flex-lg-column flex-xxl-row col-lg-4 gap-4 mt-4 mt-lg-0 align-items-md-center'>
        <button className='btn btn-success' onClick={() => callback()}>
          Фильтровать
        </button>

        <button
          className='btn btn-primary'
          onClick={() => {
            callback(true, true)
          }}
        >
          Сбросить фильтр
        </button>
      </div>
    </div>
  )
}

export default ActiveTicketsFilter
