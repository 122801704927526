import { Currency, ErrorObject, RequisiteType } from '../../settings/core/_models'
import { IPayoutTicket } from '../payoutTickets.types'

export interface PayoutTicketListObject {
  [token: string]: IPayoutTicket
}

export interface ClientRequisite {
  requisiteType: RequisiteType
  identifier: string
  currency: Currency
  token: string
  comment?: string | null
  acquiringBank?: string | null
  cardholder?: string | null
  createdAt: string
}

export interface PayoutTicketActionResponse {
  success: boolean
  ticket: Partial<IPayoutTicket>
  errors: Partial<ErrorObject>
  totalRows: number
}

export function PreparePayoutTicketsToObject(
  tickets: Array<IPayoutTicket>
): PayoutTicketListObject {
  let object: PayoutTicketListObject = {}

  tickets.forEach((item) => {
    object[item.token] = item
  })

  return object
}
