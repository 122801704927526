import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { SortableList, SortableItem } from '@thaddeusjiang/react-sortable-list'
import '@thaddeusjiang/react-sortable-list/dist/index.css'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { setLocale } from 'yup'
import { ru } from 'yup-locales'

import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getPaymentAdapterPriorities } from '../../api/getPaymentAdapterPriorities'
import { Filters } from './components/Filters'
import { postAdapterPriorities } from '../../api/postAdapterPriorities'
import { IPaymentAdapter } from '../../../../../types'
import { getPaymentAdapterList } from '../../api/getPaymentAdapterList'

setLocale(ru)

const AppSwal = withReactContent(Swal)

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Приоритет платежных адаптеров',
    path: '/settings/payment-adapter-priorities',
    isSeparator: false,
    isActive: true,
  },
]

const PaymentAdapterPriorities = () => {
  const [prioritiesList, setPrioritiesList] = useState<any>([])
  const [adapters, setAdapters] = useState<IPaymentAdapter[]>()
  const [merchant, setMerchant] = useState<string>('')
  const [paymentMethod, setPaymentMethod] = useState<string>('')
  const [externalUserFlag, setExternalUserFlag] = useState<string>('')
  const [currency, setCurrency] = useState<string>('')
  const [direction, setDirection] = useState<string>('')

  const selectedFilters = useMemo(
    () => ({
      merchantToken: merchant,
      paymentMethodType: paymentMethod,
      externalUserFlag,
      currency,
      direction,
    }),
    [merchant, paymentMethod, currency, direction, externalUserFlag]
  )

  const [formattedAdapterPrioritiesList, setFormattedAdapterPrioritiesList] = useState([])

  const fetchAdapters = async () => {
    try {
      const { data } = await getPaymentAdapterList()

      if (data && data.success) {
        setAdapters(data.paymentAdapters)
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  useEffect(() => {
    fetchAdapters()
  }, [])

  useEffect(() => {
    const formattedList: any = []

    if (prioritiesList.length) {
      prioritiesList.forEach((priority: any) => {
        const newAdapter = {
          ...priority.paymentAdapter,
          id: priority.paymentAdapter.id.toString(),
          name: priority.paymentAdapter.name,
          enabled: priority.enabled,
        }
        formattedList.push(newAdapter)
      })
    }

    if (adapters?.length) {
      adapters.forEach((adapter) => {
        if (!formattedList.find((item: any) => item.id === adapter.id.toString())) {
          const newCashBox = {
            ...adapter,
            id: adapter.id.toString(),
            name: adapter.name,
            enabled: false,
          }
          formattedList.push(newCashBox)
        }
      })
    }

    setFormattedAdapterPrioritiesList(formattedList)
  }, [prioritiesList])

  const fetchAdapterPriorities = async () => {
    try {
      const { data } = await getPaymentAdapterPriorities(selectedFilters)

      if (data && data.success) {
        setPrioritiesList(data.paymentAdapterPriorities)
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  const preparedPostData = useMemo(
    () =>
      formattedAdapterPrioritiesList.map((fa: any, index) => ({
        paymentAdapterId: +fa.id,
        enabled: fa.enabled,
        priority: index + 1,
      })),
    [formattedAdapterPrioritiesList]
  )

  const saveAdapterPriorities = async () => {
    try {
      const { data } = await postAdapterPriorities({
        priorities: preparedPostData,
        merchantToken: merchant,
        paymentMethodType: paymentMethod,
        externalUserFlag,
        currency,
        direction,
      })

      if (data && data.success && !data.errors) {
        AppSwal.fire({
          title: `Приоритет платежных адаптеров успешно сохранен!`,
          timer: 1500,
        })
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  const onToggle = (e: ChangeEvent<HTMLInputElement>, item: any) => {
    const newFormattedPrioritiesList = [...formattedAdapterPrioritiesList]

    let toggledItem: any = newFormattedPrioritiesList.find((el: any) => el.id === item.id)

    if (toggledItem) {
      toggledItem.enabled = !toggledItem.enabled
    }

    setFormattedAdapterPrioritiesList(newFormattedPrioritiesList)
  }

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Приоритет платежных адаптеров</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <Filters
            selectedFilters={selectedFilters}
            setMerchant={(value: string) => setMerchant(value)}
            setPaymentMethod={(value: string) => setPaymentMethod(value)}
            setCurrency={(value: string) => setCurrency(value)}
            setDirection={(value: string) => setDirection(value)}
            setExternalUserFlag={(value: string) => setExternalUserFlag(value)}
            onSearch={fetchAdapterPriorities}
          />
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <div className='card-rounded w-100'>
            <div className='row'>
              {formattedAdapterPrioritiesList.length ? (
                <>
                  <div className='col-lg-3'>
                    <SortableList
                      items={formattedAdapterPrioritiesList}
                      //@ts-ignore
                      setItems={setFormattedAdapterPrioritiesList}
                    >
                      {({ items }) => (
                        <>
                          {items.map((item: any, index: any) => (
                            <SortableItem key={item.id} id={item.id}>
                              <div className='d-flex align-items-center'>
                                <div className='me-4'>#{index + 1}</div>
                                <div
                                  className={clsx('d-block p-4 my-4 me-4 w-100 text-center', {
                                    'text-white bg-primary': item.enabled,
                                    'text-black bg-secondary': !item.enabled,
                                  })}
                                  style={{ height: '50px' }}
                                >
                                  {item.name}
                                </div>
                              </div>
                            </SortableItem>
                          ))}
                        </>
                      )}
                    </SortableList>
                  </div>
                  <div className='col-lg-4'>
                    {formattedAdapterPrioritiesList.map((fi: any) => (
                      <div className='flex' key={fi?.id}>
                        <div className='d-flex align-items-center' style={{ height: '76px' }}>
                          <div className='d-flex form-check form-check-solid form-switch fv-row'>
                            <input
                              style={{ cursor: 'pointer' }}
                              className='form-check-input w-45px h-30px'
                              type='checkbox'
                              checked={fi?.enabled}
                              onChange={(e) => onToggle(e, fi)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='col-lg-3 d-flex'>
                    <button
                      className='btn btn-success my-auto me-5'
                      onClick={saveAdapterPriorities}
                    >
                      Сохранить приоритет
                    </button>
                  </div>
                </>
              ) : (
                <div className='col-lg-12'>
                  Измените фильтр и нажмите "Искать", для начала работы.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentAdapterPriorities
