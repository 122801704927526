import Select from 'react-select'

import { SelectStyles } from '../../../components/Select'
import { useThemeMode } from '../../../_metronic/partials'

interface Option {
  label: string
  value: string
}
export const MultiSelect = ({ options, setValue, ...field }: any) => {
  const { mode: theme } = useThemeMode()
  const { onBlur, name } = field
  const onChange = (option: any) => {
    setValue(
      field.name,
      (option as Option[]).map((item: Option) => item.value)
    )
  }

  const getValue = () => {
    if (options) {
      return options.filter((option: any) => field.value.indexOf(option.value) >= 0)
    } else {
      return []
    }
  }
  return (
    <Select
      isMulti={true}
      onChange={onChange}
      options={options}
      onBlur={onBlur}
      value={getValue()}
      styles={SelectStyles(theme)}
      name={name}
    />
  )
}
