import { EPaymentMethods } from '../../../../../types/enums'
import { RequisiteType } from '../../core/_models'

export const PAYMENT_METHODS_TO_REQUISITE_TYPE_MAPPING: { [key: string]: string[] } = {
  'MC/VISA UAH': ['MC/VISA UAH'],
  'PRIVAT24 P2P UAH': ['PRIVAT24 P2P UAH'],
  'MC/VISA/MIR P2P RUB': ['SBER', 'ALFABANK', 'TINKOFF', 'RAIFFEISEN'],
  'SBP P2P RUB': ['SBER', 'ALFABANK', 'TINKOFF', 'RAIFFEISEN'],
}

export const HIDE_PHONE_FIELD_FOR = [
  RequisiteType.SBER as string,
  RequisiteType.ALFABANK as string,
  RequisiteType.RAIFFEISEN as string,
]

export const PHONE_FIELD_FOR_PAYMENT_METHODS = [EPaymentMethods.SBP_P2P_RUB as string]

export const ACQUIRING_BANK_REQUIRED_FIELD_FOR = [EPaymentMethods.SBP_P2P_RUB as string]
