import { useIntl } from 'react-intl'

import { formatSum } from '../../../../utils'
import { PaymentTicket } from '../core/_models'
import { IsGranted } from '../../auth/PrivateRoute'
import { ROLE, useAuth } from '../../auth'
import { convertUTCtoMSK } from '../../../../utils'
import { KTIcon } from '../../../../_metronic/helpers'
import { ticketFileGet } from '../core/_requests'
import { openFile } from '../../../../utils/openFile'

const FinishedTicketItem = (props: { ticket: PaymentTicket }) => {
  const ticket = props.ticket
  const { currentUser } = useAuth()
  const intl = useIntl()

  const onFileClick = async () => {
    const res = await ticketFileGet(ticket.bill)
    openFile(res.data)
  }

  return (
    <tr>
      <td>
        <p className='mb-4'>{ticket.token}</p>
        <div className='d-flex-column'>
        <p className='fw-bold fs-9'>Мерчант ID:</p>
        <span className='fs-9'>{ticket?.transactionId}</span>
        </div>
      </td>
      <td>{convertUTCtoMSK(ticket.createdAt)}</td>
      <td>{convertUTCtoMSK(ticket.completionDate)}</td>
      <td>{ticket.amount ? formatSum(ticket.amount) : '-'}</td>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <>
          <td>
            <span className='badge'>{ticket.issuedRequisite?.name || '-'}</span>
            <span className='separator' />
            <span className='badge'>{ticket.issuedRequisite?.requisiteType || '-'}</span>
          </td>
          <td>{ticket.sms ? ticket.sms.text : 'смс не поступило'}</td>
          <td>{ticket.externalUser?.id}</td>
        </>
      )}
      <td>{ticket.externalUser?.externalId}</td>
      <td>{ticket.merchant?.title}</td>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <>
          <td>{ticket.cashBox ? ticket.cashBox.title : ''}</td>
          <td>{ticket.executor ? ticket.executor.uuid : ''}</td>
          <td>{ticket.source.name}</td>
        </>
      )}
      <td className='text-center'>
        {ticket.bill ? (
          <span onClick={onFileClick} className='cursor-pointer'>
            <KTIcon className='fs-1' iconName='file' />
          </span>
        ) : (
          ''
        )}
      </td>
      <td>{intl.formatMessage({ id: 'ticket.status.' + ticket.status })}</td>
    </tr>
  )
}

export default FinishedTicketItem
