import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup'
import { setLocale } from 'yup'
import { ru } from 'yup-locales'
import { useFormik } from 'formik'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { RequisiteEdit, RequisiteType } from '../../core/_models'
import * as Requests from '../../core/_requests'
import { handleAPIErrors } from '../../../../common/ErrorHandler'
import { getListCashBoxesForSelect } from '../../../../common/core/_requests'
import { getHideRequisite } from '../../api'
import { ROLE, useAuth } from '../../../auth'
import { cleanUpRequisite } from './utils/cleanUpRequisite'
import { postEnableReservation } from '../../api/postEnableReservation'
import { IsGranted } from '../../../auth/PrivateRoute'
import { getPaymentMethods } from '../../api/getPaymentMethods'
import { MultiSelect } from '../../../../../components/FormikFields/MultiSelect'
import { EPaymentMethods } from '../../../../../types/enums'
import { ICashBox } from '../../../../../types'
import {
  ACQUIRING_BANK_REQUIRED_FIELD_FOR,
  HIDE_PHONE_FIELD_FOR,
  PAYMENT_METHODS_TO_REQUISITE_TYPE_MAPPING,
  PHONE_FIELD_FOR_PAYMENT_METHODS,
} from './consts'

setLocale(ru)

const AppSwal = withReactContent(Swal)

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Реквизиты',
    path: '/settings/requisites',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Создание реквизита',
    path: '/settings/requisite-add',
    isSeparator: false,
    isActive: true,
  },
]

const cashBoxIdSchema = Yup.object({
  cashBoxId: Yup.number(),
})

const cashBoxIdSchemaRequired = Yup.object({
  cashBoxId: Yup.number()
    .typeError('Обязательное поле')
    .required('Обязательное поле')
    .positive('Должно быть > 0'),
})

const requisiteSchema = Yup.object().shape({
  requisiteType: Yup.string()
    .required('Обязательное поле')
    .oneOf(
      Object.values(RequisiteType),
      'Необходимо выбрать одно из значений: ' + Object.values(RequisiteType).join(', ')
    ),
  paymentMethods: Yup.array(Yup.string()).required('Обязательное поле'),
  cardNumber: Yup.string().required('Обязательное поле'),
  phone: Yup.string()
    .when('requisiteType', (requisiteType, schema) => {
      if (
        requisiteType[0] !== RequisiteType.SBER &&
        requisiteType[0] !== RequisiteType.ALFABANK &&
        requisiteType[0] !== RequisiteType.RAIFFEISEN
      ) {
        return schema.required('Обязательное поле')
      }

      return schema.nullable()
    })
    .when('paymentMethods', (paymentMethods, schema) => {
      if (PHONE_FIELD_FOR_PAYMENT_METHODS.find((pm) => paymentMethods[0].includes(pm))) {
        return schema.required('Обязательное поле')
      }

      return schema.nullable()
    }),
  cardholderName: Yup.string().required('Обязательное поле'),
  paymentLimitByAmount: Yup.number().required('Обязательное поле'),
  paymentLimitByTicketCount: Yup.number().required('Обязательное поле'),
  name: Yup.string().required('Обязательное поле'),
  acquiringBank: Yup.string().when('paymentMethods', (paymentMethods, schema) => {
    if (ACQUIRING_BANK_REQUIRED_FIELD_FOR.find((pm) => paymentMethods[0].includes(pm))) {
      return schema.required('Обязательное поле')
    }

    return schema.nullable()
  }),
})

const defaultRequisite: RequisiteEdit = {
  accountNumber: '',
  cashBox: {} as ICashBox,
  cashBoxId: '',
  requisiteType: '',
  cardNumber: '',
  phone: '',
  cardholderName: '',
  comment: '',
  paymentUrl: '',
  paymentLimitByAmount: 150000,
  paymentLimitByTicketCount: 25,
  paymentMethods: [],
  name: '',
  acquiringBank: '',
}

const defaultListCashBoxes: Array<ICashBox> = []

const EditRequisite: React.FC = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const isEditPage = !!id

  if (isEditPage) {
    breadCrumbs[1]['title'] = 'Редактирование реквизита'
    breadCrumbs[1]['path'] = location.pathname
  }

  const [cashBoxes, setCashBoxes] = useState(defaultListCashBoxes)
  const [requisite, setRequisite] = useState(defaultRequisite)
  const [paymentMethods, setPaymentMethods] = useState<{ type: string; id: number }[]>([])
  const [loading, setLoading] = useState(false)
  const [errorAPI, setErrorAPI] = useState('')
  const [enableReservation, setEnableReservation] = useState<boolean>(false)
  const [defaultEnableReservation, setDefaultEnableReservation] = useState<boolean>(false)

  const cashBoxSelectIsAvailableByRole = useMemo(
    () =>
      currentUser?.roles.includes(ROLE.ROLE_ADMIN) ||
      currentUser?.roles.includes(ROLE.ROLE_MANAGER),
    [currentUser]
  )

  const initialValues: RequisiteEdit = {
    ...requisite,
    accountNumber: requisite.accountNumber || '',
  }
  const formik = useFormik<RequisiteEdit>({
    initialValues,
    validationSchema: requisiteSchema.concat(
      cashBoxSelectIsAvailableByRole ? cashBoxIdSchemaRequired : cashBoxIdSchema
    ),
    onSubmit: (values) => {
      setLoading(true)
      saveRequisite(values)
    },
    enableReinitialize: true,
  })

  const requisiteListByPaymentMethods = useMemo((): [] => {
    let list: [] = []

    formik.getFieldProps('paymentMethods').value.forEach((pm: any) => {
      list = [...PAYMENT_METHODS_TO_REQUISITE_TYPE_MAPPING[pm]] as []
    })

    return list
  }, [formik.getFieldProps('paymentMethods').value])

  const errorStyle = { color: 'red' }

  const SHOW_PHONE_FIELD = useMemo(() => {
    return (
      !HIDE_PHONE_FIELD_FOR.includes(formik.getFieldProps('requisiteType').value) ||
      formik.getFieldProps('paymentMethods').value.includes(EPaymentMethods.SBP_P2P_RUB)
    )
  }, [formik.getFieldProps('requisiteType').value, formik.getFieldProps('paymentMethods').value])

  useEffect(() => {
    if (isEditPage) {
      getRequisite(id + '')
    }

    if (cashBoxSelectIsAvailableByRole) {
      updateListCashBoxes()
    } else {
      if (currentUser?.cashBox && !isEditPage) {
        formik.setFieldValue('cashBoxId', currentUser.cashBox)
      }
    }

    updateListPaymentMethods()
  }, [])

  const updateListCashBoxes = async () => {
    let response = await getListCashBoxesForSelect()

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.cashBoxes) {
      setCashBoxes(response.data.cashBoxes)

      setErrorAPI('')
    } else {
      setErrorAPI(
        'Возникла ошибка при получении списка касс: ' + JSON.stringify(response.data.errors)
      )
    }
  }

  const updateListPaymentMethods = async () => {
    let response = await getPaymentMethods()

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.paymentMethods) {
      setPaymentMethods(
        response.data.paymentMethods.map((pm: any) => ({
          value: pm.type,
          label: pm.type,
          id: pm.id,
        }))
      )

      setErrorAPI('')
    } else {
      setErrorAPI(
        'Возникла ошибка при получении списка касс: ' + JSON.stringify(response.data.errors)
      )
    }
  }

  const getRequisite = async (id: string) => {
    const response = await Requests.getRequisiteAPI(id)

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.requisite) {
      let requisiteData: any = response.data.requisite

      setEnableReservation(requisiteData.enableReservation)
      setDefaultEnableReservation(requisiteData.enableReservation)
      cleanUpRequisite(defaultRequisite, requisiteData)
      setRequisite({
        ...requisiteData,
        paymentMethods: requisiteData.paymentMethods.map((pm: any) => pm.type),
        acquiringBank: requisiteData.acquiringBank || '',
      })

      setErrorAPI('')
    } else {
      setErrorAPI(
        'Возникла ошибка: ' + (response.data.errors ? response.data.errors.general_error : '')
      )
    }
  }

  const saveRequisite = async (values: RequisiteEdit) => {
    const { cashBox, ...query } = values

    if (!SHOW_PHONE_FIELD) {
      delete query.phone
    }

    if (requisite.paymentUrl === '' && query.paymentUrl === '') {
      delete query.paymentUrl
    }

    if (requisite.accountNumber === '' && query.accountNumber === '') {
      delete query.accountNumber
    }

    const promisesArray = [Requests.editRequisite(query, id ? id.toString() : '')]

    if (
      isEditPage &&
      IsGranted(
        [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
        currentUser
      ) &&
      defaultEnableReservation !== enableReservation
    ) {
      promisesArray.push(postEnableReservation(id as string))
    }

    Promise.all(promisesArray).then((values) => {
      const [editRequisiteRes, enableReservationRes] = values

      setLoading(false)

      if (!editRequisiteRes.data) {
        return false
      }

      if (enableReservationRes && !enableReservationRes?.data) {
        return false
      }

      if (
        editRequisiteRes.data.success &&
        editRequisiteRes.data.requisite &&
        (enableReservationRes ? enableReservationRes.data.success : true)
      ) {
        setDefaultEnableReservation(
          enableReservationRes?.data?.requisite?.enableReservation || enableReservation
        )

        Swal.fire({
          title: `Реквизит успешно ${isEditPage ? 'изменён' : 'создан'}`,
          icon: 'success',
          timer: 1500,
          willClose: () => {
            if (!isEditPage) {
              window.location.replace('/settings/requisites')
            }
          },
        })
      } else if (editRequisiteRes.data.errors || enableReservationRes.data.errors) {
        const errors = {
          ...editRequisiteRes.data.errors,
          ...(enableReservationRes ? enableReservationRes.data.errors : {}),
        }
        handleAPIErrors(errors, editRequisiteRes.status)
        Object.entries(errors).forEach(([key, value]) => {
          if (key === 'general_error') {
            setErrorAPI('Возникла ошибка: ' + value)
          } else {
            formik.setFieldError(key, value)
          }
        })
      }
    })
  }

  const onPressHide = (e: SyntheticEvent) => {
    e.preventDefault()

    AppSwal.fire({
      title: 'Вы точно хотите навсегда скрыть реквизит?',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Да, скрыть',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await getHideRequisite(id)

          if (response.data && response.data.success) {
            return true
          } else {
            throw new Error(response.data.errors.general_error)
          }
        } catch (err) {
          AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        AppSwal.fire({
          title: `Реквизит теперь скрыт`,
          timer: 1500,
          willClose: () => {
            window.location.replace('/settings/requisites')
          },
        })
      }
    })
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <PageTitle breadcrumbs={breadCrumbs}>{breadCrumbs[1]['title']}</PageTitle>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{breadCrumbs[1]['title']}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {cashBoxSelectIsAvailableByRole && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Касса</label>

                <div className='col-lg-8'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formik.getFieldProps('cashBoxId')}
                  >
                    <option value='' key='cashbox' disabled>
                      Выберите кассу
                    </option>
                    {Object.values(cashBoxes).map((value, index) => (
                      <option value={value.id} key={index}>
                        {value.title}
                      </option>
                    ))}
                  </select>
                  {formik.touched.cashBoxId && formik.errors.cashBoxId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.cashBoxId}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Платежный метод
              </label>

              <div className='col-lg-8 fv-row'>
                <MultiSelect
                  options={paymentMethods}
                  setValue={formik.setFieldValue}
                  {...formik.getFieldProps('paymentMethods')}
                />

                {formik.touched.paymentMethods && formik.errors.paymentMethods && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.paymentMethods}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Тип реквизита</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('requisiteType')}
                >
                  <option value='' key='requisiteType' disabled>
                    Выберите тип реквизита
                  </option>
                  {requisiteListByPaymentMethods.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </select>

                {formik.touched.requisiteType && formik.errors.requisiteType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.requisiteType}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Название реквизита
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Название реквизита'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Номер карты</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Номер карты'
                  {...formik.getFieldProps('cardNumber')}
                />
                {formik.touched.cardNumber && formik.errors.cardNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cardNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Номер счета</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Номер счета'
                  {...formik.getFieldProps('accountNumber')}
                />
                {formik.touched.accountNumber && formik.errors.accountNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.accountNumber}</div>
                  </div>
                )}
              </div>
            </div>

            {SHOW_PHONE_FIELD && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Номер телефона</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Номер телефона'
                    {...formik.getFieldProps('phone')}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Держатель</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Держатель'
                  {...formik.getFieldProps('cardholderName')}
                />
                {formik.touched.cardholderName && formik.errors.cardholderName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cardholderName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Комментарий</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Комментарий'
                  {...formik.getFieldProps('comment')}
                />
                {formik.touched.comment && formik.errors.comment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.comment}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Payment Url</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Payment Url'
                  {...formik.getFieldProps('paymentUrl')}
                />
                {formik.touched.paymentUrl && formik.errors.paymentUrl && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.paymentUrl}</div>
                  </div>
                )}
              </div>
            </div>

            {ACQUIRING_BANK_REQUIRED_FIELD_FOR.find((pm) =>
              formik.getFieldProps('paymentMethods').value.includes(pm)
            ) && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Банк-эквайер</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='acquiringBank'
                    {...formik.getFieldProps('acquiringBank')}
                  />
                  {formik.touched.acquiringBank && formik.errors.acquiringBank && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.acquiringBank}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Лимит пополнений, 24ч</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Лимит'
                  {...formik.getFieldProps('paymentLimitByAmount')}
                />
                {formik.touched.paymentLimitByAmount && formik.errors.paymentLimitByAmount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.paymentLimitByAmount}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Лимит пополнений по тикетам</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Лимит по тикетам'
                  {...formik.getFieldProps('paymentLimitByTicketCount')}
                />
                {formik.touched.paymentLimitByTicketCount &&
                  formik.errors.paymentLimitByTicketCount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.paymentLimitByTicketCount}</div>
                    </div>
                  )}
              </div>
            </div>

            {isEditPage &&
              IsGranted(
                [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
                currentUser
              ) && (
                <div className='row mb-0'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Разрешить резервирование
                  </label>

                  <div className='col-lg-8 py-3 fs-6'>
                    <div className='form-check form-check-solid form-switch fv-row py-2 mb-4'>
                      <input
                        className='form-check-input w-45px h-30px'
                        type='checkbox'
                        checked={enableReservation}
                        onChange={() => setEnableReservation(!enableReservation)}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>

          {errorAPI && (
            <div className='row mb-6 fw-bold fs-6 border-top p-9' style={errorStyle}>
              {errorAPI}
            </div>
          )}

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {isEditPage &&
              IsGranted(
                [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_OPERATOR, ROLE.ROLE_SENIOR_OPERATOR],
                currentUser
              ) && (
                <button className='btn btn-secondary me-3' disabled={loading} onClick={onPressHide}>
                  Скрыть
                </button>
              )}

            <button
              className='btn btn-secondary me-3'
              onClick={() => navigate('/settings/requisites')}
            >
              Отмена
            </button>

            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Сохранить'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Идёт сохранение...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { EditRequisite }
